import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from '../firebaseConfig';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../firebaseConfig';
import { AUTHORIZED_CHAR_LIMIT, PREMIUM_CHAR_LIMIT } from '../constants/limits';
import { useTranslation } from 'react-i18next';

// Constants for subscription details
const PREMIUM_PRICE = 39;
const PREMIUM_CURRENCY = 'RUB';
const PREMIUM_INTERVAL = 'Week';

interface SubscriptionSignUpProps {
  language: string;
}

const SubscriptionSignUp: React.FC<SubscriptionSignUpProps> = ({ language }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const functions = getFunctions(app, 'europe-west3');

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      
      // Get the invitation code from the URL if it exists
      const urlParams = new URLSearchParams(window.location.search);
      const invitationCode = urlParams.get('invite');

      // Call the Cloud Function to create or update the user
      const createOrUpdateUser = httpsCallable(functions, 'createOrUpdateUser');
      await createOrUpdateUser({ 
        invitationCode,
        email: result.user.email // Pass the email to the Cloud Function
      });

      // Clear the invitation code from the URL
      if (invitationCode) {
        window.history.replaceState({}, document.title, window.location.pathname);
      }

      return result.user;
    } catch (error) {
      console.error("Error signing in with Google", error);
      return null;
    }
  };

  const handleSubscribe = async () => {
    let currentUser = user;
    if (!currentUser) {
      currentUser = await handleGoogleSignIn();
    }
    if (currentUser) {
      initiateSubscription(currentUser);
    } else {
      console.error("Unable to sign in or retrieve user information");
    }
  };

  const initiateSubscription = (currentUser: any) => {
    if (!currentUser) {
      console.error('User not authenticated');
      return;
    }

    const cloudPayments = new (window as any).cp.CloudPayments({
      language: language
    });

    cloudPayments.charge({
      publicId: process.env.REACT_APP_CLOUDPAYMENTS_PUBLIC_ID,
      description: t('subscription.premium.description'),
      amount: PREMIUM_PRICE,
      currency: PREMIUM_CURRENCY,
      accountId: currentUser.email || '',
      invoiceId: `inv-${Date.now()}`,
      skin: 'mini',
      data: {
        cloudPayments: {
          recurrent: {
            interval: PREMIUM_INTERVAL,
            period: 1,
            customerReceipt: {
              Items: [
                {
                  label: t('subscription.premium.receiptLabel', { limit: PREMIUM_CHAR_LIMIT }),
                  price: PREMIUM_PRICE,
                  quantity: 1.00,
                  amount: PREMIUM_PRICE,
                  vat: null,
                  method: 4,
                  object: 4,
                }
              ],
              taxationSystem: 1,
              email: currentUser.email,
              amounts: {
                electronic: PREMIUM_PRICE,
                advancePayment: 0,
                credit: 0,
                provision: 0
              }
            }
          }
        }
      }
    }, 
    (options: any) => {
      // Success callback
      console.log('Payment succeed.');
    },
    (reason: string, options: any) => {
      // Failure callback
      console.error('Payment failed:', reason);
    });
  };

  return (
    <div className="bg-card rounded-lg shadow-lg p-6">
      {!user && (
        <>
          <h2 className="text-2xl font-bold mb-4 font-heading">{t('subscription.title')}</h2>
          <p className="text-muted-foreground mb-6 font-body">{t('subscription.subtitle')}</p>
        </>
      )}
      <div className="grid gap-6">
        {!user && (
          <div className="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center">
            <div className="text-left">
              <h3 className="text-xl font-bold font-heading">{t('subscription.free.title')}</h3>
              <ul className="text-sm text-muted-foreground font-body ml-2 mt-2">
                <li>• {t('subscription.free.features.0')}</li>
                <li>• {t('subscription.free.features.1', { limit: AUTHORIZED_CHAR_LIMIT })}</li>
              </ul>
            </div>
            <button 
              onClick={handleGoogleSignIn}
              className="mt-4 sm:mt-0 px-4 py-2 bg-secondary text-secondary-foreground rounded-md hover:bg-secondary/90 font-body"
            >
              {t('subscription.free.signUp')}
            </button>
          </div>
        )}
        <div className="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center">
          <div className="text-left">
            <h3 className="text-xl font-bold font-heading">{t('subscription.premium.title')}</h3>
            <p className="text-muted-foreground font-body ml-2">
              {t('subscription.premium.price', { price: PREMIUM_PRICE, currency: PREMIUM_CURRENCY, interval: PREMIUM_INTERVAL.toLowerCase() })}
            </p>
            <ul className="text-sm text-muted-foreground font-body ml-2 mt-2">
              <li>• {t('subscription.premium.features.0')}</li>
              <li>• {t('subscription.premium.features.1', { limit: PREMIUM_CHAR_LIMIT })}</li>
            </ul>
          </div>
          <button onClick={handleSubscribe} className="mt-4 sm:mt-0 px-4 py-2 bg-primary text-primary-foreground rounded-md hover:bg-primary/90 font-body">
            {t('subscription.premium.subscribe')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSignUp;