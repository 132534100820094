import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'ru' : 'en';
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('appLanguage', newLanguage);
  };

  const isHomePage = location.pathname === '/';
  const footerClassName = `${isHomePage ? 'relative' : 'fixed bottom-0 left-0 right-0'} bg-gray-100 p-4 flex justify-between items-center`;

  return (
    <footer className={footerClassName}>
      <div className="flex space-x-4">
        <Link to="/" className="text-sm text-gray-600 hover:text-gray-900">
          🏠
        </Link>
        <Link to="/terms" className="text-sm text-gray-600 hover:text-gray-900">
          {t('footer.terms')}
        </Link>
        <Link to="/privacy" className="text-sm text-gray-600 hover:text-gray-900">
          {t('footer.privacy')}
        </Link>
      </div>
      <button onClick={toggleLanguage} className="text-sm text-gray-600 hover:text-gray-900">
        {i18n.language === 'en' ? 'RU' : 'EN'}
      </button>
    </footer>
  );
};

export default Footer;