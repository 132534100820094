import React, { useRef, useEffect, useState, useCallback } from 'react';
import './App.css';
import GrammarChecker, { CorrectionType } from 'langapps-shared-components/dist/GrammarChecker';
import SubscriptionSignUp from './components/SubscriptionSignUp';
import InviteLink from './components/InviteLink';
import Footer from './components/Footer';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import { signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { auth, db } from './firebaseConfig';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { doc, onSnapshot } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from './firebaseConfig';
import { useTranslation } from 'react-i18next';
import { Routes, Route } from 'react-router-dom';
import { AUTHORIZED_CHAR_LIMIT } from './constants/limits';
import SubscriptionInfo from 'langapps-shared-components/dist/SubscriptionInfo';
import { subscriptionService } from './services/subscriptionService';
import { correctGrammar, improveGrammar } from './services/grammarService';

function AppContent() {
  const subscriptionRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const [isLimitHit, setIsLimitHit] = useState(false);
  const [hasPremiumAccess, setHasPremiumAccess] = useState(false);
  const [premiumUntil, setPremiumUntil] = useState<Date | null>(null);
  const [correctionsUsedToday, setCorrectionsUsedToday] = useState(0);
  const [firstCorrectionTimestamp, setFirstCorrectionTimestamp] = useState<any | null>(null);

  const { t, i18n } = useTranslation();

  const updateUserStatus = useCallback(() => {
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      
      const unsubscribe = onSnapshot(userDocRef, (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          const premiumDate = userData.premiumUntil?.toDate();
          setHasPremiumAccess(premiumDate > new Date());
          setPremiumUntil(premiumDate);
          setCorrectionsUsedToday(userData.correctionsUsedToday || 0);
          setFirstCorrectionTimestamp(userData.firstCorrectionTimestamp || null);
        }
      });

      return unsubscribe;
    } else {
      setHasPremiumAccess(false);
      setPremiumUntil(null);
      setCorrectionsUsedToday(0);
      setFirstCorrectionTimestamp(null);
    }
  }, [user]);

  useEffect(() => {
    const unsubscribe = updateUserStatus();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [updateUserStatus]);

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      
      const invitationCode = localStorage.getItem('invitationCode');

      // Call the Cloud Function to create or update the user
      const functions = getFunctions(app, 'europe-west3');
      const createOrUpdateUser = httpsCallable(functions, 'createOrUpdateUser');
      await createOrUpdateUser({ 
        invitationCode,
        email: result.user.email // Pass the email to the Cloud Function
      });

      // Clear the invitation code from the URL
      if (invitationCode) {
        window.history.replaceState({}, document.title, window.location.pathname);
      }

      // Check user status after sign-in
      await updateUserStatus();

    } catch (error) {
      console.error("Error signing in with Google", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      // Clear any user-related state
      setHasPremiumAccess(false);
    } catch (error) {
      console.error("Error signing out", error);
      // Force clear the auth state even if there's an error
      auth.signOut();
    }
  };

  useEffect(() => {
    const setupLanguage = async () => {
      const savedLanguage = localStorage.getItem('appLanguage');
      
      if (savedLanguage) {
        await i18n.changeLanguage(savedLanguage);
      } else {
        const defaultLanguage = 'ru'; // Set Russian as default
        const userLanguage = navigator.language.split('-')[0];
        const languageToUse = userLanguage === 'en' ? 'en' : defaultLanguage;
        
        await i18n.changeLanguage(languageToUse);
        localStorage.setItem('appLanguage', languageToUse);
      }
    };

    setupLanguage();
  }, [i18n]);

  const urlParams = new URLSearchParams(window.location.search);
  const urlInvitationCode = urlParams.get('invite');
  
  // If invitation code is in URL and not in storage, save it
  if (urlInvitationCode && !localStorage.getItem('invitationCode')) {
    localStorage.setItem('invitationCode', urlInvitationCode);
  }

  const processText = useCallback(async (text: string, type: CorrectionType) => {
    if (type === 'correct') {
      return await correctGrammar(text);
    } else if (type === 'improve') {
      return await improveGrammar(text);
    }
    throw new Error('Invalid correction type');
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      <Routes>
        <Route path="/" element={
          <>
            <div className="w-full max-w-4xl mx-auto pt-4 sm:pt-6 lg:pt-8 pb-12 px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between items-center mb-4">
                <div>
                  {user && <InviteLink />}
                </div>
                <div>
                  {user ? (
                    <button
                      onClick={handleSignOut}
                      className="text-sm text-muted-foreground hover:underline"
                    >
                      {t('auth.signOut')}
                    </button>
                  ) : (
                    <button
                      onClick={handleGoogleSignIn}
                      className={`text-sm text-muted-foreground hover:underline ${
                        isLimitHit ? 'animate-pulse font-bold text-primary' : ''
                      }`}
                    >
                      {isLimitHit
                        ? t('auth.signInWithLimit', { limit: AUTHORIZED_CHAR_LIMIT })
                        : t('auth.signIn')}
                    </button>
                  )}
                </div>
              </div>
              <GrammarChecker 
                processText={processText}
                isUserLoggedIn={!!user}
                hasPremiumAccess={hasPremiumAccess}
                premiumUntil={premiumUntil}
                correctionsUsedToday={correctionsUsedToday}
                firstCorrectionTimestamp={firstCorrectionTimestamp}
                setIsLimitHit={setIsLimitHit} // Add this new prop
              />

              {!hasPremiumAccess && (
                <>
                  <div ref={subscriptionRef} className="mt-8" style={{ height: '100%' }}>
                    <SubscriptionSignUp language={i18n.language} />
                  </div>
                </>
              )}
              {user && hasPremiumAccess && 
                <div className="mt-8 text-center text-sm text-muted-foreground">
                  <SubscriptionInfo 
                    getSubscriptionInfo={subscriptionService.getSubscriptionInfo}
                    cancelSubscription={subscriptionService.cancelSubscription}
                    isUserLoggedIn={!!user}
                    texts={{
                      loading: t('common.loading'),
                      fetchError: t('subscription.fetchError'),
                      cancelConfirmation: t('subscription.cancelConfirmation'),
                      cancelSuccess: t('subscription.cancelSuccess'),
                      cancelError: t('subscription.cancelError'),
                      noActiveSubscription: t('subscription.noActiveSubscription'),
                      info: t('subscription.info'),
                      status: t('subscription.status'),
                      nextPayment: t('subscription.nextPayment'),
                      cancelButton: t('subscription.cancelButton'),
                      statusTypes: {
                        active: t('subscription.statusTypes.active'),
                        cancelled: t('subscription.statusTypes.cancelled'),
                        no_subscription: t('subscription.statusTypes.expired')
                      }
                    }}
                  />
                </div>
              }
            </div>
          </>
        } />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <div className="flex flex-col min-h-screen"> {/* Add this wrapper */}
        <div className="flex-grow"> {/* Add this wrapper */}
          <AppContent />
        </div>
        <Footer />
      </div>
    </AuthProvider>
  );
}

export default App;
