import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useTranslation } from 'react-i18next'; // Add this import

export default function InviteLink() {
  const { t } = useTranslation(); // Add this hook
  const { user } = useAuth();
  const [inviteCode, setInviteCode] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchInviteCode = async () => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            setInviteCode(userDoc.data().invitationCode);
          }
        } catch (err) {
          console.error('Error fetching invite code:', err);
          setError(t('inviteLink.fetchError')); // Use translated string
        }
      }
    };

    fetchInviteCode();
  }, [user, t]); // Add 't' to the dependency array

  const handleShare = async () => {
    if (inviteCode) {
      const inviteLink = `${window.location.origin}?invite=${inviteCode}`;
      
      if (navigator.share && window.innerWidth <= 768) {
        try {
          await navigator.share({
            title: t('inviteLink.shareTitle'),
            url: inviteLink,
          });
        } catch (err) {
          console.error('Error sharing:', err);
        }
      } else {
        // Fallback for browsers that don't support Web Share API
        navigator.clipboard.writeText(inviteLink);
        alert(t('inviteLink.copied'));
      }
    }
  };

  if (!user || error || !inviteCode) return null;

  return (
    <button
      onClick={handleShare}
      className="px-4 py-2 bg-primary text-primary-foreground rounded-md hover:bg-primary/90 font-body text-sm"
    >
      {t('inviteLink.button')} {/* Use translated string */}
    </button>
  );
}