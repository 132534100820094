import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../firebaseConfig';

const functions = getFunctions(app, 'europe-west3'); // Specify the region

export const correctGrammar = async (text: string): Promise<string> => {
  const grammarCorrect = httpsCallable(functions, 'grammarCorrect');
  const result = await grammarCorrect({ text });
  return result.data as string;
};

export const improveGrammar = async (text: string): Promise<string> => {
  const grammarImprove = httpsCallable(functions, 'grammarImprove');
  const result = await grammarImprove({ text });
  return result.data as string;
};