import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../firebaseConfig';
import { SubscriptionInfoType } from 'langapps-shared-components/dist/SubscriptionInfo';

const functions = getFunctions(app, 'europe-west3');

export const subscriptionService = {
  getSubscriptionInfo: async (): Promise<SubscriptionInfoType> => {
    const getSubscriptionInfo = httpsCallable<any, SubscriptionInfoType>(functions, 'getSubscriptionInfo');
    const result = await getSubscriptionInfo();
    return result.data;
  },

  cancelSubscription: async (): Promise<void> => {
    const cancelSubscription = httpsCallable(functions, 'cancelSubscription');
    await cancelSubscription();
  }
};