import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

const Terms: React.FC = () => {
  const { i18n } = useTranslation();
  const TermsComponent = React.lazy(() => import(`./${i18n.language}/Terms`));

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <TermsComponent />
    </Suspense>
  );
};

export default Terms;